import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return(
        <div>
        </div>
    )
}

export default Footer;