import React, {useState, useEffect, useRef} from "react";

import { Link } from "react-router-dom";



function PassiveIncome() {
 


  return (
   <div>
      <section className="bg-[#077206] w-full h-screen">
        <div className="navbar bg-[#ffc107] w-full">
          <div className=" w-4/5  m-auto nav p-4 flex bg-[#ffc107] align-center">
            <div className="w-1/2 md:w-1/6">
              {/* <Bounce bottom> */}
                <Link to="/"><img src="img/logo.png" className="rounded-full w-24" /></Link>
              {/* </Bounce> */}
            </div>
            <div className="md:w-2/3 flex justify-center ">
              <ul className="hidden md:block md:flex align-center   items-center justify-center">
                <li className="nav-item mr-6">
                  <Link className="" to="/">Home</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link className="" to="/#about">Our story</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link className="" to="/#tokenomics">Tokenomics</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link to="/#roadmap">Roadmap</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link to="/#contactus">Contact Us</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link to="passiveincome">Passive income</Link>
                </li>
                <li className="nav-item ">
                  <Link to="/#media">Media</Link>
                </li>
                
              </ul>
             
            </div>
            <div className="w-1/2 md:w-1/6 flex justify-center items-center">
                <a href="#buy" className="float-right relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg md:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black group-hover:text-white">Buy Now</span>
                </a>
            </div>
          </div>
          <div className="w-7/8 md:w-4/5 m-auto">
            <ul className="flex md:hidden align-center   items-center justify-center">
                 <li className="nav-item mr-6">
                  <Link className="" to="/">Home</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link className="" to="/#about">Our story</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link className="" to="/#tokenomics">Tokenomics</Link>
                </li>
                <li className="nav-item mr-6">
                  <Link to="/#roadmap">Roadmap</Link>
                </li>
            </ul>
            <ul className="flex md:hidden  align-center   items-center justify-center">
                 <li className="nav-item mr-6">
                  <Link to="/#contactus">Contact Us</Link>
                </li>
                 <li className="nav-item mr-6">
                  <Link to="passiveincome">Passive income</Link>
                </li>
                <li className="nav-item ">
                  <Link to="/#media">Media</Link>
                </li>
                
              </ul>
          </div>
       </div>
       <div className="header-intro p-4 ">
          {/* <Zoom bottom> */}
            <img className="w-full md:w-1/3  m-auto animation-buzz " src="img/landmark.png" style={{}}></img>
          {/* </Zoom> */}
          <h1 className="purples text-5xl lg:text-7xl 2xl:text-8xl mt-0 mb-3">Coming Soon</h1>
          {/* <p className="text-3xl lg:text-5xl 2xl:text-7xl font-bold text-white drop-shadow-lg shadow-black w-4/5 mt-8">
            Be With Us For The Future Of Blockchain And AI
          </p> */}
          {/* <a href="#buy" download className="float-right relative inline-block px-8 py-4 font-bold group text-xl mt-20">
            <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
            <span className="absolute inset-0 w-full h-full bg-white border-4 rounded-lg border-black group-hover:bg-black"></span>
            <span className="relative text-black group-hover:text-white">Buy Now</span>
          </a> */}
       </div>
      </section>
   </div>
  );
}

export default PassiveIncome;
