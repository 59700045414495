import React, {useState, useEffect, useRef} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import PassiveIncome from "./views/PassiveIncome";
import Home from "./views/Home";

import { LanguageProvider } from "./components/container/Language";

import { Text } from "./components/languages/Text/Text";

function App() {
 


  const [showButton, setShowButton] = useState(false);
  

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);


  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  const handleScroll = (event) => {
    // alert('yes!!!');
    console.log(event.currentTarget.scrollTop);
    console.log(event.currentTarget.offsetHeight);
  };


  return (
    <div>
      <Router>
        <LanguageProvider> 
          <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/passiveincome" element={<PassiveIncome />} />
          </Routes>
        </LanguageProvider>
      </Router>

      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          &#8679;
        </button>
        
      )}
       {showButton && (
        <div  className="copy-btn">
         <p className="w-full md:w-1/5 m-auto cursor-pointer" onClick={() => {navigator.clipboard.writeText("0x416A625f4a04581243D5044C2783588Dd6412392"); }}><Text tid={"copyText"}></Text></p>
        </div>
      )}
    </div>
  );
}

export default App;
