import React, {useState, useEffect, useRef, useContext} from "react";
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";


import Header from "./layout/Header";
import Footer from "./layout/Footer";
import PassiveIncome from "./PassiveIncome";


import { Text } from '../components/languages/Text/Text';

import { languageOptions } from "../components/languages";
import { LanguageContext } from "../components/container/Language";

import LanguageSelector from "../components/container/LanguageSelector";

export const data = [
  ["Task", "Hours per Day"],
  ["Uniswap Liquidity", 70],
  ["Investors & Dev Team", 16],
  ["Exchange Listing", 6],
  ["Marketing", 3],
  ["Rewards", 5], // CSS-style declaration
];

export const options = {
  legend: "none",
  pieSliceText: "label",
  
  pieStartAngle: 100,
};



function Home() {
 
  
  const myRef = useRef();
  const progressRef = useRef();

  const [showButton, setShowButton] = useState(false);
  
  const [coinClass, setCoinClass] = useState(false);

  const [blueWidth, setBlueWidth] = useState('0%');
  const [redWidth, setRedWidth] = useState('0%');
  const [greenWidth, setGreenWidth] = useState('0%');
  const [yellowWidth, setYellowWidth] = useState('0%');
  const [orangeWidth, setOrangeWidth] = useState('0%');
  const [showAlert, setShowAlert] = useState(true);

  const [dropdownShow, setDropdownShow] = useState(false);
//   const [lang, setLang] = useState('English');

  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });

    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      console.log('entry', entry);
      console.log('entry.isIntersecting', entry.isIntersecting);
      
      
      if(!entry.isIntersecting) {         
        setCoinClass("w-full md:w-1/4 m-auto coin-img");
      } else {
        setCoinClass("w-full md:w-1/4 m-auto coin-img coin-img-animation");    
      }

    });

    observer.observe(myRef.current);
  }, []);


  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      console.log('entry', entry);
      console.log('entry.isIntersecting', entry.isIntersecting);
      
      
      if(entry.isIntersecting) {         
        setBlueWidth('70%');
        setRedWidth('16%');
        setGreenWidth('6%');
        setYellowWidth('5%');
        setOrangeWidth('3%');
      } else {
        setBlueWidth('0%');
        setRedWidth('0%');
        setGreenWidth('0%');
        setYellowWidth('0%');
        setOrangeWidth('0%');
      }

    });

    observer.observe(progressRef.current);
  }, []);



  const handleScroll = (event) => {
    alert('yes!!!');
    console.log(event.currentTarget.scrollTop);
    console.log(event.currentTarget.offsetHeight);
  };

  const handleDropdown = () => {
    setDropdownShow(!dropdownShow);
  }

  const handleLangSelect = (lang) => {
    // setLang(lang);
    var langSimple = '';
    if(lang == 'English') {
        langSimple = 'en';
    } else if(lang == 'Arabic') {
        langSimple = 'ab';
    }
    setDropdownShow(false);
    userLanguageChange(langSimple);
  }


  const handleHideAlert = () => {
    setShowAlert(false);
  }

  return (
    <div>
      {showAlert && (
        <section className="bg-green w-full p-4 text-center relative">
          <p className="text-white text-xl md:text-3xl ">The site will be launched on 22, September</p>
          <p className="close-btn" onClick={() => { handleHideAlert()}}>&times;</p>
        </section>
      )}
      
      <section className="bg-[#077206] w-full h-screen">
        <div className="navbar bg-[#fedb39] w-full">
          <div className="w-full 2xl:w-4/5  m-auto nav p-4 flex bg-[  #fedb39] align-center">
            <div className="w-1/3 md:w-1/6">
              <Bounce bottom>
                <Link to="/"><img src="img/menu-logo.jpg" className=" w-24" /></Link>
              </Bounce>
            </div>
            <div className="md:w-2/3 flex justify-center ">
              <ul className="hidden md:block md:flex align-center   items-center justify-center">
                <li className="nav-item mr-6">
                  <Link className="" to="/"><Text tid={"home"}></Text></Link>
                </li>
                <li className="nav-item mr-6">
                  <a className="" href="#about"><Text tid={"ourStory"}></Text></a>
                </li>
                <li className="nav-item mr-6">
                  <a className="" href="#tokenomics"><Text tid={"tokenomics"}></Text></a>
                </li>
                <li className="nav-item mr-6">
                  <a href="#roadmap"><Text tid={"roadmap"}></Text></a>
                </li>
                <li className="nav-item mr-6">
                  <a href="#contactus"><Text tid={"contactUs"}></Text></a>
                </li>
                <li className="nav-item mr-6">
                  <Link to="passiveincome"><Text tid={"passiveIncome"}></Text></Link>
                </li>
                <li className="nav-item ">
                  <a href="#media"><Text tid={"media"}></Text></a>
                </li>
                
              </ul>
             
            </div>
            <div className="w-2/3 md:w-1/6 flex justify-between items-center">
                <p className="flex items-center mr-2 text-xl cursor-pointer text-underlined underline" id="dropdownDefaultButton" data-dropdown-toggle="dropdown"  data-dropdown-trigger="hover" onClick={()=>{handleDropdown()}}>
                    {userLanguage == "en" ? "English" : "Arabic"}
                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                </p>
                <div className="relative"></div>
                {dropdownShow && (
                     <div id="dropdown" className="z-10 block a-dropdown bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                     <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                         <li>
                             <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black" onClick={()=> {handleLangSelect('English')}}><Text tid={"en"}/></a>
                         </li>
                         <li>
                             <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black" onClick={()=> {handleLangSelect('Arabic')}}><Text tid={"ab"}/></a>
                         </li>
                     
                     </ul>
                 </div>
                )}
                {/* <LanguageSelector/> */}
                <a href="#buy" className="float-right relative inline-block px-4 py-2 2xl:px-8 2xl:py-4 font-bold group text-md md:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black group-hover:text-white"><Text tid={"buyNow"}/></span>
                </a>
            </div>
                
          </div>
          <div className="w-7/8 md:w-4/5 m-auto">
            <ul className="flex md:hidden align-center   items-center justify-center">
              <li className="nav-item mr-6">
                <a className="" href="#home"><Text tid={"home"}></Text></a>
              </li>
              <li className="nav-item mr-6">
                <a className="" href="#about"><Text tid={"ourStory"}/></a>
              </li>
              <li className="nav-item mr-6">
                <a className="" href="#tokenomics"><Text tid={"tokenomics"}/></a>
              </li>
              <li className="nav-item ">
                <a className="#roadmap"><Text tid={"roadmap"}/></a>
              </li>
            </ul>
            <ul className="flex md:hidden p-2 align-center   items-center justify-center">
                <li className="nav-item mr-6">
                  <a href="#contactus"><Text tid={"contactUs"}/></a>
                </li>
                <li className="nav-item mr-6">
                  <Link to="passiveincome"><Text tid={"passiveIncome"}/></Link>
                </li>
                <li className="nav-item ">
                  <Link to="/#media"><Text tid={"media"}/></Link>
                </li>
                
              </ul>
          </div>
       </div>
       <div className="header-intro p-4 ">
          {/* <Zoom bottom> */}
            <img className="w-full md:w-1/3  m-auto animation-buzz " src="img/landmark.png" style={{}}></img>
          {/* </Zoom> */}
          <h1 className="purples text-4xl lg:text-7xl 2xl:text-7xl mt-0 mb-3"><Text tid={"logoName"}/></h1>
          <div className="w-full text-center">
            <a onClick={() => {navigator.clipboard.writeText("0x416A625f4a04581243D5044C2783588Dd6412392"); }}  className="cursor-pointer relative inline-block px-4 py-2 2xl:px-8 2xl:py-4 font-bold group text-md  m-auto mt-2">
                <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                <span className="absolute inset-0 w-full h-full bg-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                <span className="relative text-black group-hover:text-white"><Text tid={"copyText"}/></span>
            </a>
            </div>
      
       </div>
      </section>

      <section className="py-20 md:py-36 bg-[#ffc107]  header-intro" id="about">
        <Fade bottom>
          <h1 className="purples text-3xl lg:text-4xl 2xl:text-6xl  text-[#00540E]"><Text tid={"ourStory"}/></h1>
          
        </Fade>
        <div className="w-full md:w-4/5  m-auto p-4 md:flex items-center ">
        
          <div className="w-full md:w-1/2 mt-4 p-2">
            <p className="common-text text-md md:text-2xl mt-10">
                <Text tid={"story1"}/>
             </p>
             <br/>
             <p className="common-text text-md md:text-2xl mt-10">
              <Text tid={"story2"}/>
             </p>
          </div>  
          <div className="w-full block md:w-1/2    md:mt-0 p-4 ">
            <img src="img/story.png" className="m-auto w-auto story-img"  />
          </div>
         
        </div>

        <div className="w-full md:w-4/5  m-auto p-4 md:flex items-center ">
          
           <div className="w-full hidden md:block md:w-1/2    md:mt-0 p-4 ">
            <img src="img/story2.png" className="w-auto  md:float-right story-img"  />
          </div>

          <div className="w-full md:w-1/2 mt-4 p-2">
            <p className="common-text text-md md:text-2xl mt-10">
            <Text tid={"story3"}/>
            </p>
          </div>  
        </div>


        <div className="w-full md:w-4/5  m-auto p-4 md:flex items-center ">
          <div className="w-full md:w-1/2 mt-4 p-2">
            <p className="common-text text-md md:text-2xl mt-10">
             <Text tid={"story4"}/>
            </p>
          </div>  
          <div className="w-full block md:w-1/2    md:mt-0 p-4 ">
            <img src="img/story3.png" className="w-auto md:float-right story-img1"  />
          </div>
        </div>


        <div className="w-full md:w-4/5  m-auto p-4 md:flex items-center ">
         <div className="w-full  hidden md:block md:w-1/2    md:mt-0 p-4 ">
            <img src="img/story4.png" className="w-auto md:w-3/4 md:float-right story-img1" />
          </div>
          <div className="w-full md:w-1/2 mt-4 p-2">
            <p className="common-text text-md md:text-2xl mt-10">
            As news of Sempsun's AI Coin spread throughout Springfield, the townsfolk were captivated by the possibilities it offered. Marge, always the voice of reason, saw the potential for ﬁnancial stability and decided to invest in Sempsun AI Coin. She believed in Bart's vision and wanted to secure a brighter future for her family.
            <Text tid={"story5"}/>
            </p>
          </div>  
        </div>

        <div className="w-full md:w-4/5  m-auto p-4 md:flex items-center ">
          <div className="w-full md:w-1/2 mt-4 p-2">
            <p className="common-text text-md md:text-2xl mt-10">
            Meanwhile, Lisa, the ever-curious and intellectually driven daughter, saw an opportunity to combine her passion for AI with her desire to make a positive impact. She joined forces with Bart, using her knowledge to fine-tune the AI algorithms behind Smart Coin Together, they aimed to create a token that would not only benefit Springfield but also the world at large.
            <Text tid={"story6"}/>
            </p>
          </div>  
          <div className="w-full block md:w-1/2    md:mt-0 p-4 ">
            <img src="img/story5.png" className="w-auto md:w-3/4 md:float-right story-img1" />
          </div>
        </div>


        <div className="w-full md:w-4/5  m-auto p-4 md:flex items-center ">
         <div className="w-full  hidden md:block md:w-1/2    md:mt-0 p-4 ">
            <img src="img/story6.png" className="w-auto md:w-3/4 md:float-right story-img1" />
          </div>
          <div className="w-full md:w-1/2 mt-4 p-2">
            <p className="common-text text-md md:text-2xl mt-10">
            <Text tid={"story7"}/>
           </p>
          </div>  
        </div>
        <div className="w-full md:w-4/5  m-auto p-4 md:flex items-center ">
         
          <div className="w-full mt-4 p-2">
            <p className="common-text text-md md:text-2xl mt-10">
            
            <Text tid={"story8"}/>
            </p>
            <p className="common-text text-md md:text-2xl mt-10">
           
            <Text tid={"story9"}/>
            </p>
            <p className="common-text text-md md:text-2xl mt-10">
              
              <Text tid={"story10"}/>
            </p>
          </div>  
        </div>



      </section>
      
    


      <section className="py-20  md:py-40 bg-black" id="tokenomics">
        <div className="w-full md:w-4/5  m-auto p-4 header-intro">
          <Fade bottom>
            <h1 className="text-3xl lg:text-4xl 2xl:text-6xl purples text-[#ffc107] text-center"> <Text tid={"tokenomics"}/></h1>
          </Fade>
          {/* <img src="img/token.png" className="tokenomics-img" style={{"position": "absolute"}}/> */}
          <div className="p-0 md:p-8 md:flex mt-10   w-full  ">
           
            <div className="w-full md:w-1/2  justify-center flex justify-center">
           
              <img src="img/tokenomics.png"></img>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center mt-4" ref={progressRef}>
              <div className="w-full md:w-4/5 text-xl xl:text-2xl 2xl:text-3xl p-8 px-4 bg-black text-white  justify-center items-center ">
                 <h1 className="mb-4 text-center" onScroll={handleScroll}><Text tid={"totalSupply"}/></h1>
                
              
                <div>
                  <label className="label"><Text tid={"t_uniswap"}/> <span className="color-green bolder text-2xl">(70%)</span></label>
                  <div className="animated-progress progress-blue">
                    <span data-progress="70" style={{'width': blueWidth}}></span>
                  </div>
                  <label className="label"><Text tid={"t_investor"}/> <span className="color-green bolder text-2xl">(16%)</span>
                  </label>
                  <div className="animated-progress progress-red">
                    <span data-progress="60" style={{'width': redWidth}}></span>
                  </div>
                  <label className="label"><Text tid={"t_exchange"}/> <span className="color-green bolder text-2xl">(6%)</span></label>
                  <div className="animated-progress progress-green">
                    <span data-progress="70" style={{'width': greenWidth}} ></span>
                  </div>
                  <label className="label"><Text tid={"t_rewards"}/> <span className="color-green bolder text-2xl">(5%)</span>
                    </label>
                  <div className="animated-progress progress-yellow">
                    <span data-progress="85" style={{'width': yellowWidth}}></span>
                  </div>
                  <label className="label"><Text tid={"t_marketing"}/> <span className="color-green bolder text-2xl">(3%)</span>
                    </label>
                  <div className="animated-progress progress-orange">
                    <span data-progress="85" style={{'width': orangeWidth}}></span>
                  </div>
              </div>
              <hr className="mt-2 mb-2 text-black color-green"/>
              <ul className="mt-4 common-text  text-sm md:text-lg md:text-xl">
                 
                  <li><Text tid={"tokenA"}></Text></li>
                  <ul className="pl-4">
                    <li style={{listStyle: "square"}}><Text tid={"tokenAA"}></Text></li>
                    <li style={{listStyle: "square"}}><Text tid={"tokenAB"}></Text></li>
                    
                  </ul>
                  <li> <Text tid={"tokenB"}></Text>  </li>
                  <ul className="pl-4">
                    <li style={{listStyle: "square"}}>  <Text tid={"tokenBA"}></Text></li>
                    <li style={{listStyle: "square"}}>  <Text tid={"tokenBB"}></Text> </li>
                    <li style={{listStyle: "square"}}>    <Text tid={"tokenBC"}></Text> </li>
                  </ul>
                </ul>

              </div>
              
            </div>
          </div>
        </div>
      </section>
      
      <section className="bg-[#0B0B0E] coin-section p-14">
        <div className="background-overlay"></div>

        <div className="flex justify-center items-center" >
          <img src="img/coin.png" className={coinClass} ref={myRef} style={{borderRadius: "50%"}}></img>
        </div>

        <div className="w-full text-center">
         <a href="doc/whitepaper.pdf" download className=" relative inline-block px-4 py-2 2xl:px-8 2xl:py-4 font-bold group text-md  m-auto mt-20">
            <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
            <span className="absolute inset-0 w-full h-full bg-white border-4 rounded-lg border-black group-hover:bg-black"></span>
            <span className="relative text-black group-hover:text-white"><Text tid={"whitepaper"}/></span>
          </a>
        </div>
        
      </section>

      <section className="py-20 bg-white" id="buy">
        <div className="w-7/8 md:w-4/5 m-auto p-4 block md:flex items-center">
         
          <div className="w-full md:w-1/2 text-center justify-center header-intro">
            <Fade bottom>
              <h1 className="text-3xl lg:text-4xl 2xl:text-6xl purples text-[#00540E] "> <Text tid={"tokenName"}/></h1>
            </Fade>
            
            
            <div className="w-7/8 md:w-4/5 m-auto mt-12 common-text">
          
              <form className="bg-[#ffc107] shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                    <Text tid={"usdToPay"}></Text>
                  </label>
                  <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="USD To Pay"/>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2" for="password">
                    <Text tid={"yourWalletAddress"}></Text>
                  </label>
                  <input className="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="Your Wallet Address"/>
                  <p className="text-gray-700 text-xs italic"></p>
                </div>
                <div className="flex items-center justify-between">
                  <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    <Text tid={"buyCard"}></Text>
                  </button>
                 
                </div>
              </form>
            
            </div>
          </div>

          <div className="w-full md:block md:w-1/2">
            <img src="img/buytoken.jpg" className="w-full md:w-3/5 m-auto" />
          </div>
        </div>
      </section>

      <section className="py-8 md:py-20 bg-[#00540E]" id="roadmap">
        <div className="w-7/8 md:w-4/5 m-auto p-4  items-center justify-center header-intro">
          <Fade bottom>
            <h1 className="text-3xl lg:text-4xl 2xl:text-6xl purples text-white text-center justify-center"><Text tid={"roadmap"}></Text></h1>
          </Fade>
          <img src="img/roadmap.png" className="w-60 rounded-full mt-6"/>
          
          <div className="mt-10 lg:flex common-text">
           
            <div className="w-full lg:w-1/3">
              <div className="w-full md:w-4/5  p-4 m-auto post-it">
                <p className="mt-4 text-2xl md:text-3xl text-center font-bold"><Text tid={"phase1"}></Text></p>
                <p className="mt-2 text-xl text-center" style={{height: "84px"}}><Text tid={"phase10"}></Text> </p>
                <ul className=" p-4 sticky-ul">
                  <li><span className="mr-2">✅</span><Text tid={"phase11"}></Text></li>
                  <li><span className="mr-2">✅</span><Text tid={"phase12"}></Text></li>
                  <li><span className="mr-2">✅</span><Text tid={"phase13"}></Text></li>
                  <li><span className="mr-2">✅</span><Text tid={"phase14"}></Text></li>
                  <li><span className="mr-2">✅</span><Text tid={"phase15"}></Text></li>
                  <li><span className="mr-2">✅</span><Text tid={"phase16"}></Text> </li>
                  <li><span className="mr-2">✅</span><Text tid={"phase17"}></Text></li>
                  <li><span className="mr-2">✅</span><Text tid={"phase18"}></Text> </li>
                  <li><span className="mr-2">✅</span><Text tid={"phase19"}></Text></li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/3 mt-4 md:mt-0">
              <div className="w-full md:w-4/5  p-4 m-auto post-it">
                <p className="mt-4 text-2xl md:text-3xl text-center font-bold"><Text tid={"phase2"}></Text></p>
                <p className="mt-2 text-xl text-center" style={{height: "84px"}}> <Text tid={"phase20"}></Text> </p>
                <ul className="  p-4 list-disc sticky-ul">
                  <li><span className="mr-2"></span>  <Text tid={"phase21"}></Text></li>
                  <li> <span className="mr-2"></span>  <Text tid={"phase22"}></Text></li>
                  <li><span className="mr-2"></span> <Text tid={"phase23"}></Text></li>
                  <li> <span className="mr-2"></span> <Text tid={"phase24"}></Text></li>
                  <li><span className="mr-2"></span> <Text tid={"phase25"}></Text></li>
                  <li> <span className="mr-2"></span> <Text tid={"phase26"}></Text> </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/3 mt-4 md:mt-0">
              <div className="w-full md:w-4/5  p-4 m-auto post-it">
                <p className="mt-4 text-2xl md:text-3xl text-center font-bold"><Text tid={"phase3"}></Text></p>
                <p className="mt-2 text-xl text-center" style={{height: "84px"}}> <Text tid={"phase30"}></Text> </p>
                <ul className="  p-4 list-disc sticky-ul">
                  <li> <span className="mr-2"></span> <Text tid={"phase31"}></Text> </li>
                  <li> <span className="mr-2"></span> <Text tid={"phase32"}></Text> </li>
                  <li> <span className="mr-2"></span> <Text tid={"phase33"}></Text> </li>
                  <li> <span className="mr-2"></span> <Text tid={"phase34"}></Text></li>
                  <li><span className="mr-2"></span>  <Text tid={"phase35"}></Text></li>
                  <li> <span className="mr-2"></span> <Text tid={"phase36"}></Text> </li>
                </ul>
              </div>
            </div>
          
            
          </div>
        </div>
      </section>
      
      <section className="py-20 bg-white" id="contactus">
        <div className="w-full md:w-4/5  m-auto p-4 md:flex ">
         
          <div className="w-full md:w-1/2 mt-4 p-2 header-intro">
            <Fade bottom>
              <h1 className="purples text-3xl lg:text-4xl 2xl:text-6xl  text-[#00540E]"><Text tid={"joinUs"}></Text></h1>
             
            </Fade>
            
           

            <p className="p-3 w-full">
              <a onClick={() => {navigator.clipboard.writeText("0x416A625f4a04581243D5044C2783588Dd6412392");}} className="cursor-pointer text-center mt-8 w-full float-right relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg md:text-xl">
                <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                <span className="absolute inset-0 w-full h-full bg-[#00540E]  text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                <span className="relative text-white  group-hover:text-white text-center"><i className="fa-brands fa-ethereum"></i><Text tid={"copyText"}></Text></span>
              </a>
            </p>
            <div className="mt-4 grid grid-cols-2 2xl:grid-cols-3 gap-3 w-full">
              <div className=" p-3">
                <a href="https://www.facebook.com/profile.php?id=100095593389786&mibextid=LQQJ4d" target="_blank" className=" w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"facebook"}></Text></span>
                </a> 
              </div>
              <div className="p-3">
                <a href="http://www.reddit.com/user/SempsunAi" target="_blank" className=" w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"reddit"}></Text></span>
                </a>
              </div>
              <div className=" p-3">
                <a href="https://twitter.com/SempsunAI" target="_blank" className="w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"twitter"}></Text></span>
                </a>
              </div>
              <div className="p-3">
                <a href="https://www.quora.com/profile/SempsunAI-2-0" target="_blank" className=" w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"quora"}></Text></span>
                </a>
              </div>
              <div className="p-3">
                <a href="https://youtube.com/@SempsunAI2.0?si=MaxO3CNx2DJjAZWW" target="_blank" className=" w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"youtube"}></Text></span>
                </a>
              </div>
              <div className=" p-3">
                <a href="http://www.pinterest.com/sempsunai/" target="_blank" className="w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"pinterest"}></Text></span>
                </a>
              </div>
              <div className=" p-3">
                <a href="https://discord.gg/3RP33uEq" target="_blank"  className="w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"discord"}></Text></span>
                </a>
              </div>
              <div className=" p-3">
                <a href="https://t.me/SempsunAI2" target="_blank" className="w-full text-center relative inline-block px-4 sm:px-8 py-4 font-bold group text-lg xl:text-xl">
                  <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-black rounded-lg group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                  <span className="absolute inset-0 w-full h-full bg-white bg-white text-white border-4 rounded-lg border-black group-hover:bg-black"></span>
                  <span className="relative text-black  group-hover:text-white"><i className="fa-brands fa-ethereum"></i><Text tid={"telegram"}></Text></span>
                </a>
              </div>
            </div>
          </div>

          <div className="w-full block md:w-1/2    md:mt-0 p-4 ">
            <img src="img/landing.jpg" className="w-full " />
          </div>
         
        </div>
      </section>

      <section className="py-20 bg-[#ffc107]" id="contactus">
        <div className="w-full md:w-4/5  m-auto p-4 md:flex ">
         
          <div className="w-full  mt-4 p-2 header-intro">
            <Fade bottom>
              <h1 className="purples text-3xl lg:text-4xl 2xl:text-6xl  text-[#00540E]"><Text tid={"howToBuy"}></Text></h1>
             
            </Fade>
            <div className="m-auto items-center mt-10 w-full md:w-4/5">
              <video width="100%" height="540" controls className="mt-4 m-auto" />
                <source src="movie.mp4" type="video/mp4" />
            </div>
          </div>


         
        </div>
      </section>

      <section className="footer  px-4 py-20 pt-10">
          <div className="container w-7/8 md:w-4/5">
        <div className="w-full mb-0 md:mb-4
          md:flex
          justify-between
          border-bottom pb-16 2xl:pb-24">
          <div className="w-7/8  md:w-2/5 ">
            <p className="text-white font-bold
              text-xl
              xl:text-2xl title"><Text tid={"stayInTouch"}></Text></p>
            <p className="text-white mt-3 text-md
              xl:text-xl opacity-50"><Text tid={"footerDesc"}></Text></p>
          </div>
          <div className="w-7/8 md:w-2/5">
            <div className="flex justify-between">
              <p className="text-white mt-3
                text-md
                xl:text-xl"><Text tid={"enterInfo"}></Text></p>
              <p className="text-white mt-3
                text-md
                xl:text-xl font-medium
                cursor-pointer"><Text tid={"subscribe"}></Text></p>
            </div>
            <input className="mt-2 p-3
              custom-input
              w-full" placeholder="Name"/>
            <input className="mt-2 p-3
              custom-input
              w-full" placeholder="Email Address"/>
            {/* <input className="mt-2 p-3
              custom-input
              w-full" placeholder="Phone Number"/> */}
           
          </div>
        </div>
            
            <div className="w-full 2xl:flex
              justify-between py-4
              text-white">
              <p className="align-recenter">©
                <Text tid={"copyRight"}></Text></p>
              <ul className="hidden md:flex align-recenter">
                <li className="mr-4
                  cursor-pointer"><a href="https://www.facebook.com/profile.php?id=100095593389786&mibextid=LQQJ4d" target="_blank"><Text tid={"facebook"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://twitter.com/SempsunAI" target="_blank"><Text tid={"twitter"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="http://www.pinterest.com/sempsunai/" target="_blank"><Text tid={"pinterest"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://youtube.com/@SempsunAI2.0?si=MaxO3CNx2DJjAZWW" target="_blank"><Text tid={"youtube"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://www.quora.com/profile/SempsunAI-2-0" target="_blank"><Text tid={"quora"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://t.me/SempsunAI2" target="_blank"><Text tid={"telegram"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://discord.gg/3RP33uEq" target="_blank"><Text tid={"discord"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="http://www.reddit.com/user/SempsunAi" target="_blank"><Text tid={"reddit"}></Text></a></li>
                
              </ul>

              <ul className="flex md:hidden align-recenter">
                 <li className="mr-4
                  cursor-pointer"><a href="https://www.facebook.com/profile.php?id=100095593389786&mibextid=LQQJ4d" target="_blank"><Text tid={"facebook"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://twitter.com/SempsunAI" target="_blank"><Text tid={"twitter"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="http://www.pinterest.com/sempsunai/" target="_blank"><Text tid={"pinterest"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://youtube.com/@SempsunAI2.0?si=MaxO3CNx2DJjAZWW" target="_blank"><Text tid={"youtube"}></Text></a></li>
              </ul>
              <ul className="flex md:hidden align-recenter">
                <li className="mr-4
                  cursor-pointer"><a href="https://www.quora.com/profile/SempsunAI-2-0" target="_blank"><Text tid={"quora"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://t.me/SempsunAI2" target="_blank"><Text tid={"telegram"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="https://discord.gg/3RP33uEq" target="_blank"><Text tid={"discord"}></Text></a></li>
                <li className="mr-4
                  cursor-pointer"><a href="http://www.reddit.com/user/SempsunAi" target="_blank"><Text tid={"reddit"}></Text></a></li>
              </ul>
              <div className="hidden 2xl:flex
                align-recenter">
                
                <p><Text tid={"companyName"}></Text></p>
              </div>
            </div>
          </div>
      </section>

    </div>
  );
}

export default Home;
